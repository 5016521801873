<template>
    <div class="loading-progress">
        <div class="loading-img">
            <!-- <img src="@/assets/img/loading2.gif" alt="" width="10%"> -->
            <div class="loadingio-spinner-dual-ball-xlvck54xhg"><div class="ldio-ooqtnb49qz">
            <div></div><div></div><div></div>
            </div></div>
            <br><br><strong>Loading...</strong>
            <el-progress :text-inside="true" :stroke-width="5" :percentage="loadingValue" color="#6F52ED"></el-progress>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
        }
    },
    props:{
        loadingValue:{
            default: 100,
        },
    }
}
</script>
<style lang="scss" scoped>
.loading-progress{
    width: 100%;
    height: 100%;
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    .loading-img{
        opacity: .9;
    }
}
.el-progress{
    width: 75%;
    margin:0 auto;
}
@keyframes ldio-ooqtnb49qz-o {
            0%    { opacity: 1; transform: translate(0 0) }
        49.99% { opacity: 1; transform: translate(100px,0) }
        50%    { opacity: 0; transform: translate(100px,0) }
        100%    { opacity: 0; transform: translate(0,0) }
        }
        @keyframes ldio-ooqtnb49qz {
            0% { transform: translate(0,0) }
        50% { transform: translate(100px,0) }
        100% { transform: translate(0,0) }
        }
        .ldio-ooqtnb49qz div {
        position: absolute;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        top: 100px;
        left: 50px;
        }
        .ldio-ooqtnb49qz div:nth-child(1) {
        background: #4454ff;
        animation: ldio-ooqtnb49qz 1s linear infinite;
        animation-delay: -0.5s;
        }
        .ldio-ooqtnb49qz div:nth-child(2) {
        background: #ffffff;
        animation: ldio-ooqtnb49qz 1s linear infinite;
        animation-delay: 0s;
        }
        .ldio-ooqtnb49qz div:nth-child(3) {
        background: #4454ff;
        animation: ldio-ooqtnb49qz-o 1s linear infinite;
        animation-delay: -0.5s;
        }
        .loadingio-spinner-dual-ball-xlvck54xhg {
        width: 300px;
        height: 200px;
        display: inline-block;
        overflow: hidden;
        background: none;
        }
        .ldio-ooqtnb49qz {
        width: 100%;
        height: 100%;
        position: relative;
        transform: translateZ(0) scale(1);
        backface-visibility: hidden;
        transform-origin: 0 0; /* see note above */
        }
        .ldio-ooqtnb49qz div { box-sizing: content-box; }
</style>