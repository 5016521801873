<template>
    <div>
        <div class="footer-container">
            &copy; 2021 ExpoNow<span class="bk">Blackhole Creative</span>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.footer-container{
    width: 100%;
    color: #333;
    padding: 10px 0;
}
.bk{
    margin-left: 5px;
    color: #4454ff;
}
</style>
<script>
export default {
    data(){
        return{
        }
    }
}
</script>