import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import {token,multipart} from '../config'
Vue.use(VueAxios, axios);
let userId = null
let token = 'Bearer '+window.localStorage.getItem("token");
let refresh = ()=>{
    const thisToken = 'Bearer '+window.localStorage.getItem("token")
    if(window.localStorage.hasOwnProperty('token')){
        axios.defaults.headers.common['Authorization'] = thisToken;
    }else{
        axios.defaults.headers.common['Authorization'] = "";
    }
    if(window.localStorage.hasOwnProperty('userId')){
        userId = localStorage.getItem('userId')
    }
}
refresh();
let url = "https://exp-ctl-partyisland-dev.dlll.nccu.edu.tw/api"
if (/^https\:\/\/.*/.test(window.location.origin)) {
    url = `${window.location.origin}/api`
}
let HostUrl = ""
let getToken = param => Vue.$http.post(`${url}/user/token`,param)
let vendorSignup = param => Vue.$http.post(`${url}/auth/local/signup_seller`,param)
let updateAvatar = param => Vue.$http.post(`${url}/upload/avatar`,param)
let updateSellerPassword = param => Vue.$http.patch(`${url}/user/${userId}`,param)//更新廠商帳號、密碼
let getSellerModel = (param) =>  {
    let res
    if(param){
        res = Vue.$http.get(`${url}/seller/${param}/models`)
        return res
    }else{
        res = Vue.$http.get(`${url}/seller/${userId}/models`)
        return res
    }
}

let getSellerShareModel = (param) =>  {
    
    let res
    if(param){
        res = Vue.$http.get(`${url}/seller/${param}/models/share/1`)
        return res
    }else{
        res = Vue.$http.get(`${url}/seller/${userId}/models/share/1`)
        return res
    }

    }


let uploadSellerModel = (param,id) => {
    console.log(id)
    let res
    if(id){
        res = Vue.$http.post(`${url}/seller/${id}/models`,param,{headers: {'Content-Type': 'multipart/form-data'}})
        return res
    }else{
        res = Vue.$http.post(`${url}/seller/${userId}/models`,param,{headers: {'Content-Type': 'multipart/form-data'}})
        return res
    }
}
let deleteSellerModel = (param,id) => {
    let res 
    if(id){
        res = Vue.$http.delete(`${url}/seller/${id}/models/${param}`)
        return res
    }else{
        res = Vue.$http.delete(`${url}/seller/${userId}/models/${param}`)
        return res
    }   
}
let updateModelData = (param,id,sid) => {
    let res
    if(sid){
        res = Vue.$http.patch(`${url}/seller/${sid}/models/${id}`,param)
        return res
    }else{
        res = Vue.$http.patch(`${url}/seller/${userId}/models/${id}`,param)
        return res
    }
    
}
//{headers: {'Content-Type': 'multipart/form-data'}}
let uploadModelImg = (param,id) => {
    let res 
    if(id){
        res = Vue.$http.post(`${url}/seller/${id}/upload/model_image`,param)
        return res
    }else{
        res = Vue.$http.post(`${url}/seller/${userId}/upload/model_image`,param)
        return res
    }
}
let uploadSellerCover = param =>  Vue.$http.post(`${url}/seller/${userId}/upload/exhibition_cover`,param)
let uploadSellerSignboard = param =>  Vue.$http.post(`${url}/seller/${userId}/upload/exhibition_signboard`,param)
let uploadSellerInfoOpen = param => Vue.$http.patch(`${url}/seller/${userId}/profile/show_detail`,param)

let updateSellerModelImg = param => Vue.$http.post(`${url}/seller/${userId}/upload/scene_image`,param)
let updateSellerExhData = (param,id) => Vue.$http.patch(`${url}/seller/${userId}/exhibitions/${id}`,param)
let updateSellerRoomData = (param,id) => Vue.$http.patch(`${url}/seller/${userId}/exhibitions/room/${id}`,param)
let updateSellerRoomScene = (param,id) => Vue.$http.patch(`${url}/seller/${userId}//exhibitions/room/${id}/scene`,param)
let getVenderProfile = param => Vue.$http.get(`${url}/seller/${userId}/profile`);
let getSellerProfileForAdmin = param => Vue.$http.get(`${url}/seller/${param}/profile`)//營運方拿廠商資料
let updateSellerProfile = (param,id) => Vue.$http.patch(`${url}/seller/${id}/profile`,param)//更新廠商資料
let getUserData = param => Vue.$http.get(`${url}/user/${userId}`)//取得用戶的身分、id、nickname、account
let getUserAccByAdmin = id => Vue.$http.get(`${url}/user/${id}`)//取得用戶的身分、id、nickname、account
let updateUserAccByAdmin = (param,id) => Vue.$http.patch(`${url}/user/${id}`,param);
let upgradeAccount = param => Vue.$http.post(`${url}/user/${localStorage.getItem('userId')}/upgrade_to_seller`,param)
let login = param => Vue.$http.post(`${url}/auth/local/signin`,param)
let logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('type');
    localStorage.removeItem('userId');
};
let getVendorExh = param => Vue.$http.get(`${url}/seller/${userId}/exhibitions`,param)
let getSellerExhDetail = (id) => Vue.$http.get(`${url}/seller/${userId}/exhibitions/${id}`)
let createSellerExh = param => Vue.$http.post(`${url}/seller/${userId}/exhibitions`,param)

let getExhTag = param => Vue.$http.get(`${url}/seller/${userId}/exhibitions/tags`)

let userSignup = param => Vue.$http.post(`${url}/auth/local/signup`,param)

let uploadScene = param => Vue.$http.post(`${url}/test/scene`,param)
let getSpecificScene = param => Vue.$http.get(`${url}/test/scene`,param)

let getExhibitions = param => Vue.$http.get(`${url}/exhibitions`,param)
let getSellerExhibition = (param,eid) => Vue.$http.get(`${url}/exhibitions/${eid}`,param)
let getExhibitionsFacets = param => Vue.$http.get(`${url}/exhibitions/facets`,param)
let getExhibitionsTrack = param => Vue.$http.get(`${url}/exhibitions/track`,param)
let getExhibitionsFavorite = uid => Vue.$http.get(`${url}/user/${uid}/fav`)
let setExhibitionsFavorite = param => Vue.$http.post(`${url}/user/${userId}/fav/${param}`)

let getSellerData = param => Vue.$http.get(`${url}/admin/sellers`,param)
let updateSellerData = param => Vue.$http.patch(`${url}/admin/sellers`,param)
let getMailData = param => Vue.$http.get(`${url}/admin/mails/contact`,param)
let sendMail = (param,eid) => Vue.$http.post(`${url}/exhibitions/${eid}/contact`,param)
let sendVerified = param => Vue.$http.post(`${url}/auth/local/send_verified_email`,param)
let resendVerified = param => Vue.$http.post(`${url}/auth/local/send_verified_email`,param)
let verifiedMail = param => Vue.$http.post(`${url}/auth/local/verify_email`,param);
let signupByVerified = param => Vue.$http.post(`${url}/auth/local/signup_by_verified_email`,param);
let sendResetPassMail = param => Vue.$http.post(`${url}/auth/local/send_reset_password_email`,param);
let resetForgotPass = param => Vue.$http.post(`${url}/auth/local/reset_password`,param);
//////////////////////////////////////////////////////////////////////////////////////////////////////
//send signup param and currentAccount to blochain
// let blockchainAccount = param_blockchain => Vue.$http.post('http://blockchain-omekas.ccstw.nccu.edu.tw:7845/signin_param', param_blockchain);
//申請帳號
let blockchainAccount = param_blockchain => Vue.$http.post(`https://blockchain-asset-platform.dlll.nccu.edu.tw/api_signin_param`, param_blockchain);
//登入
let blockchainLogin = loginRes => Vue.$http.post(`https://blockchain-asset-platform.dlll.nccu.edu.tw/api_PI_login`, loginRes);
//展品互動
let interactExisting = param => Vue.$http.post(`https://blockchain-asset-platform.dlll.nccu.edu.tw/filter/api_interactExisting`, param);
let exhInteract = param => Vue.$http.post(`https://blockchain-asset-platform.dlll.nccu.edu.tw/filter/api_exhInteract`, param);
let getUserLicence = uid => Vue.$http.post(`https://blockchain-asset-platform.dlll.nccu.edu.tw/filter/api_licence_list/${uid}`);
let checkTicket = (uid, roomId) => Vue.$http.post(`https://blockchain-asset-platform.dlll.nccu.edu.tw/filter/api_ticket/${uid}/${roomId}`);
let inExp = uid => Vue.$http.get(`https://blockchain-asset-platform.dlll.nccu.edu.tw/filter/api_inEXP/${uid}`);
let exhEXP = roomId => Vue.$http.get(`https://blockchain-asset-platform.dlll.nccu.edu.tw/filter/api_exhEXP/${roomId}`);
//////////////////////////////////////////////////////////////////////////////////////////////////////
export {
    getToken,
    vendorSignup,
    updateAvatar,
    getSellerProfileForAdmin,
    updateSellerProfile,
    getUserData,
    getUserAccByAdmin,
    updateUserAccByAdmin,
    login,logout,
    getVendorExh,
    userSignup,
    uploadScene,
    getSpecificScene,
    getExhibitions,
    getSellerExhibition,
    getExhibitionsFacets,
    getExhibitionsTrack,
    getExhibitionsFavorite,
    setExhibitionsFavorite,
    getSellerData,
    getMailData,
    getVenderProfile,
    HostUrl,
    updateSellerPassword,
    updateSellerData,
    getSellerModel,
    uploadSellerModel,
    uploadModelImg,
    deleteSellerModel,
    updateModelData,
    getExhTag,
    createSellerExh,
    getSellerExhDetail,
    uploadSellerCover,
    uploadSellerSignboard,
    updateSellerExhData,
    updateSellerRoomData,
    updateSellerModelImg,
    updateSellerRoomScene,
    uploadSellerInfoOpen,
    sendMail,
    refresh,
    sendVerified,
    verifiedMail,
    resendVerified,
    signupByVerified,
    resetForgotPass,
    sendResetPassMail,
    upgradeAccount,
    getSellerShareModel,
    blockchainAccount,
    blockchainLogin,
    interactExisting,
    exhInteract,
    getUserLicence,
    checkTicket,
    inExp,
    exhEXP
}
