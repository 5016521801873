<template>
    <div class="user-login-page">
        <div class="main-wrap"></div>
        <div class="bgImg"></div>
        <div class="content-wrap">
            <div class="logoWrap">
                <img src="@/assets/img/exhLogo.svg" alt="">
                <h3>Hold an OnlineExhibitions with Expo Now<br>Different scenes, different experience<br>Get closer to each other !</h3>
            </div>
            <div class="loginWrap">
                <img class='head-img' src="@/assets/img/loginBanner.jpg">
                <div class='visitor-title' >{{title}}</div>
                <div class="inputWrap" v-if="state==1">
                    <div class="input-account input-box">
                        <label for="">Account</label>
                        <input type="text" name="account" v-model="form.account" placeholder="Enter your username" required>
                    </div>
                    <div class="input-password input-box">
                        <label for="">Password</label>
                        <input type="password" name="password" v-model="form.password" placeholder="Enter your password" v-on:keydown="keydownEvent" required>
                    </div>
                </div>
                <div class="inputWrap" v-if="state==2">
                    <div class="input-account input-box">
                        <label for="">Email</label>
                        <input type="text" name="email" v-model="form.email" placeholder="Enter your email">
                    </div>
                    <div class="input-account input-box">
                        <label for="">Account</label>
                        <input type="text" name="account" v-model="form.account" placeholder="Enter your username" v-on:keydown="keydownEvent">
                    </div>
                </div>
                <div class="inputWrap" v-if="state==3">
                    <div class="input-password input-box">
                        <label for="">Password</label>
                        <input type="password" name="password" v-model="form.password" placeholder="Please enter 8-12 characters">
                    </div>
                    <div class="input-password input-box">
                        <label for="">Confirm password</label>
                        <input type="password" name="password2" v-model="form.password2" placeholder="Enter your password again" v-on:keydown="keydownEvent">
                    </div>
                </div>
                <div class="inputWrap" v-if="state==4">
                    <div class="input-account input-box">
                        <label for="">Enter a name for yourself</label>
                        <input type="text" name="nickname" v-model="form.nickname" placeholder="Enter your name" v-on:keydown="keydownEvent">
                    </div>
                </div>
                <div class="button-container" :class="{row: state >= 1}">
                    <button class="cancel" @click="switchState(0)">{{buttonA}}</button>
                    <button class="submit" @click="switchState(1)" :style="unset?{opacity:`.5`}:{}">{{buttonB}}</button>
                </div>
                <div class="info-container">
                    <span v-show="state==0">OR</span>
                    <p class="p1" v-show="state==0" @click="state=5;buttonA=`Cancel`;buttonB=`Confirm`;title=`Just  to be a visitor`;">I just want to be a visitor</p>
                    <p class="p2">If you have any questions, please contact us !</p>
                    <p class="phone"><span></span>0937-732-863</p>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
.user-login-page{
    .content-wrap{
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 50%;
        left: 50%;
        min-height: 100vh;
        transform: translate(-50%,-50%);
        justify-content: space-between;
        align-items: center;
        display: flex;
        .logoWrap{
            width: 40%;
            height: auto;
            left: 14%;
            position: absolute;
            margin-top: 0;
            top: 50%;
            transform: translateY(-50%);
            @media (max-width:600px){
                width: 85%;
                height: 25vh;
                margin-top: 0;
                left: 50%;
                top: 50px;
                transform: translateX(-50%);
            }
            img{
                width: 44%;
                @media (max-width:600px){
                    width: 20%;
                }
            }
            h3{
                font-size: $font*25;
                color: #fff;
                line-height: 2;
                letter-spacing: 3px;
                @media (max-width: 600px) {
                    font-size: $font*40;
                }
            }
        }
        .loginWrap{
            position: absolute;
            height: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            width: 25%;
            min-width: 300px;
            background-color: #fff;
            border-radius: 15px;
            right: 4%;
            @media (max-width: 600px) {
                right: 50%;
                transform: translateX(50%);
                height: 55vh;
                bottom: 50px;
            }
            .info-container{
                width: 85%;
                .p1{
                    cursor: pointer;
                    position: relative;
                    &:after{
                        content: '';
                        width: 60%;
                        border-bottom: #000 1px solid;
                        position: absolute;
                        top: 120%;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
                .p2{
                    width: 100%;
                    font-size: $font*18;
                }
                .phone{
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    width: 100%;
                    span{
                        background-image: url('~@/assets/img/icon/phone.svg');
                        padding-bottom: 4%;
                        margin-right: 4%;
                        width: 4%;
                        display: inline-block;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                    }
                }
            }
            .head-img{
                width: 75%;
                // max-height: 275px;
            }
            .visitor-title{
                font-size: $font*26;
                font-weight: bold;
                line-height: $font*20;
                height: 5%;           
                display: flex;
                justify-content: center;
                align-items: center;
                @media (max-width:600px) {
                    font-size: $font*25;
                }
            }
            .login-header{
                width: 65%;
                // height: 10%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .button-container{
                width: 70%;
                height: 15%;
                // max-height: 110px;
                // min-height: 60px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                &.row{
                    // height: ;
                    flex-direction: row;
                    button{
                        width: 45%;
                        min-height: 30px;
                        height: 40%;
                    }
                }
                button{
                    width:100%;
                    height: 40%;
                    font-size: $font*20;
                    line-height: $font*20;
                    border-radius: 6px;
                    letter-spacing: 2px;
                    font-weight: 400;
                    cursor: pointer;
                    @media (max-width: 600px) {
                        font-size: $font*55;
                    }
                }
            }
            .inputWrap{
                width: 70%;
                height: 25%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                .input-box{
                    width: 100%;
                    height: 40%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-evenly;
                    label{
                        align-self: flex-start;
                        font-size: $font*18;
                        line-height: $font*18;
                        margin-bottom: 5px;
                    }
                    input{
                        width: 100%;
                        height: 40px;
                        padding: 0 10px;
                        &:focus{
                            border: 1px solid #6f52ed;
                        }
                    }
                }
            }
            .login-btn{
                width: 85%;
                height: 10%;
                border-radius: 7px;
                background-color: #6f52ed;
                color: #fff;
                cursor: pointer;
                font-size: $font*25;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .main-wrap{
        width: 100%;
        height: 100vh;
        min-height: 100vh;
        // background: linear-gradient(135deg, #8C76EE 0 25%, #6344AE 75% 100%);
        background:linear-gradient(125deg, rgba(140,118,238,1), rgba(99, 68, 234,1));
    }
    .bgImg{
        background-repeat: no-repeat;
        position: absolute;
        width: 30vw;
        height: 20vw;
        background-image: url('~@/assets/img/bgImg.png');
        background-size: contain;
        bottom: 0;
        left: 0;
        @media (max-width:600px){
            position: fixed;
            background-size: contain;
            width: 400px;
            height: 400px;
            bottom: -125px;
        }
    }
    @media (max-width:600px) {
        .content-wrap{
            width: 100vw;
            height: 100%;
            flex-direction: column;
            .logoWrap{
                width: 100%;
                img{
                    width: 100px
                }
                h3{
                    width: 100%;
                    font-size: $font*50;
                }
            }
            .loginWrap{
                width: 95%;
                .visitor-title{
                    font-size: $font*80;
                    margin-top: 50px;
                }
                .head-img{
                    display: none;
                }
                .inputWrap{
                    height: 35%;
                    width: 85%;
                    .input-box{
                        label{
                            margin-bottom: 10px;
                            font-size: $font*80;
                        }
                    }
                }
                .button-container{
                    height: 30%;
                }
                .info-container{
                    .p1, .p2{
                        font-size: $font*60;
                    }
                }
            }
        }
    }
}
</style>
<script>
import {login, logout, userSignup} from '@/api';
import { mapActions } from 'vuex';
const STATE_INIT = 0,
      STATE_LOGIN = 1,
      STATE_REG = 2;
export default {
    data(){
        return{
            state: STATE_INIT,
            form:{
                account:'',
                password:'',
                password2:'',
                email:'',
                nickname:'',
            },
            title: 'I want to visit the Exhibition',
            buttonA: 'Login',
            buttonB: 'Signup'
        }
    },
    computed:{
        unset(){
            if(this.state===1){
                if(this.form.account==='')
                    return true;
                else if(this.form.password==='')
                    return true;
            }
            else if(this.state===2){
                if(this.form.email==='')
                    return true;
                else if(this.form.account==='')
                    return true;
                else if(!this.validateEmail(this.form.email))
                    return true;
            }
            else if(this.state===3){
                if(this.form.password!==this.form.password2)
                    return true;
                else if(this.form.password==='')
                    return true;
                else if(this.form.password2==='')
                    return true;
            }
            else if(this.state===4){
                if(this.form.nickname==='')
                    return true;
            }
            return false;
        }
    },
    methods:{
        ...mapActions(['setLogin']),
        async loginAccount(){
            if(this.form.account!=''&& this.form.password!=''){
                let vm = this;
                let res = await login(this.form).catch(function (error) {
                if (error.response) {
                    if(error.response.status==422||error.response.status==404){
                        vm.$message({
                            showClose: true,
                            message:`${error.response.data.message}`,
                            type: 'warning',
                            duration: 1500,
                            onClose: ()=>{
                            }
                        });
                    }
                    return error.response.status
                  }
                });
                if(!res.data) return;
                let auth = await this.setLogin(res.data.data);
                switch (auth) {
                    case 'normal':
                        if(this.$route.query.eid){
                            this.$router.push({name: 'Exhibitions', query: {
                                id: this.$route.query.eid,
                                room: this.$route.query.room?this.$route.query.room:1
                            }});
                            this.loginSuccessfulMsg();
                            break;
                        }
                        this.loginSuccessfulMsg();
                        this.$router.push({name:'Entrance'});
                        break;
                    default:
                        logout();
                        this.loginFailedMsg();
                        break;
                }
            }
            else{
                this.loginFailedMsg();
            }
        },
        loginSuccessfulMsg(){
            this.$message({
                showClose: true,
                message: `You've logged in successfully!`,
                type: 'success',
                duration: 3500,
            });
        },
        loginFailedMsg(){
            this.$message({
                showClose: true,
                message: `There's no relevant user account.`,
                type: 'error',
                duration: 1000,
            });
        },
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        async regiserAccount(){//給遊客註冊用
            if(this.form.account=='' || this.form.password=='' || this.form.password2=='' || this.form.nickname==''
            || this.form.email=='' || (this.form.password!=this.form.password2)){
                this.errorMessage('Please make sure you have filled correct data');
                return;
            }
            let userData = {
                account: this.form.account,
                nickname: this.form.nickname,
                password: this.form.password,
                email: this.form.email,
            }
            // let res = await userSignup(userData);
            let vm = this;
            let res = await userSignup(userData).catch(function (error) {
            if (error.response) {
                if(error.response.status==422||error.response.status==404){
                    vm.$message({
                        showClose: true,
                        message:`${error.response.data.message}`,
                        type: 'warning',
                        duration: 1500,
                        onClose: ()=>{
                        }
                    });
                }
                else if(error.response.status==409){
                    vm.$message({
                        showClose: true,
                        message:`Account already exist.`,
                        type: 'warning',
                        duration: 1500,
                        onClose: ()=>{
                        }
                    });
                }
                return error.response.status
                }
            });
            if(res.data.status == 201){
                this.$message({
                    showClose: true,
                    message: `You've registered successfully! entering exhibition now...`,
                    type: 'success',
                    duration: 1500,
                    onClose: ()=>{
                        this.form.account = userData.account;
                        this.form.password = userData.password;
                        this.loginAccount();
                    }
                });
            }
        },
        errorMessage(msg){
            this.$message({
                showClose: true,
                message: msg,
                type: 'error'   
            });
        },
        switchState(action){
            if(action){
                switch (this.state) {
                    case 0:
                        this.buttonA = 'Cancel';
                        this.buttonB = 'Next'
                        this.title = 'Signup';
                        this.state = 2;
                        break;
                    case 1:
                        this.loginAccount();
                        break;
                    case 2:
                        if(!this.form.email || !this.form.account){
                            this.errorMessage('Please make sure you have filled email and account.');
                            return;
                        }
                        else if(!this.validateEmail(this.form.email)){
                            this.errorMessage('Please make sure you have filled valid email.');
                            return;
                        }
                        this.buttonA = 'Previous';
                        this.buttonB = 'Next';
                        this.state = 3;
                        break;
                    case 3:
                        if(!this.form.password || !this.form.password2){
                            this.errorMessage('Please make sure you have filled password and confirmed password.');
                            return;
                        }
                        else if((this.form.password!==this.form.password2)){
                            this.errorMessage('Please make sure that confirmed password is same as password.')
                            return;
                        }
                        else if((this.form.password.length < 8 || this.form.password.length >= 12) || (this.form.password2.length < 8 || this.form.password2.length >= 12)){
                            this.errorMessage('Please notice the character limit of password (8-12)')
                            return;
                        }
                        this.buttonA = 'Previous';
                        this.buttonB = 'Confirm';
                        this.state = 4;
                        break;
                    case 4:
                        if(!this.form.nickname){
                            this.errorMessage('Please enter your nickname.');
                            return;
                        }
                        this.regiserAccount();
                        break;
                    case 5:
                        this.visitorLogin();
                        break;
                    default:
                        break;
                }
            }
            else{
                switch (this.state) {
                    case 0:
                        this.buttonA = 'Cancel';
                        this.buttonB = 'Confirm'
                        this.title = 'Login';
                        this.state = 1;
                        break;
                    case 1:
                        this.buttonA = 'Login';
                        this.buttonB = 'Signup';
                        this.title = 'I want to visit the Exhibition';
                        this.state = 0;
                        break;
                    case 2:
                        this.buttonA = 'Login';
                        this.buttonB = 'Signup';
                        this.title = 'I want to visit the Exhibition';
                        this.state = 0;
                        break;
                    case 3:
                        this.buttonA = 'Cancel';
                        this.buttonB = 'Next';
                        this.state = 2;
                        break;
                    case 4:
                        this.buttonA = 'Previous';
                        this.buttonB = 'Next';
                        this.state = 3;
                        break;
                    case 5:
                        this.buttonA = 'Login';
                        this.buttonB = 'Signup';
                        this.title = 'I want to visit the Exhibition';
                        this.state = 0;
                        break;
                    default:
                        break;
                }
            }
        },
        visitorLogin(){
            localStorage.setItem('token', 'visitor');
            localStorage.setItem("type", 'visitor');
            this.$router.push({name: 'Entrance'});
            this.$message({
                showClose: true,
                message: `Hi ${this.form.nickname}! You've entered our exhibitions with visitor identity!`,
                type: 'success',
                duration: 3500,
                onClose: ()=>{
                }
            });
        },
        keydownEvent(e){
            if(e.keyCode===13){
                if(this.state===5){
                    this.visitorLogin();
                }
                else if(this.state===4){
                    this.regiserAccount();
                }
                else if(this.state===1){
                    this.loginAccount();
                }
                else{
                    this.switchState(1);
                }
            }
        },
    } 
}
</script>