<template>
    <div class="firsttimetips">
        <transition name="el-fade-in-linear">
            <div>
                <div class="title">How to play ?</div>
                <img src="@/assets/img/firstTimeTips.svg" alt="" class="tipsImg">
                <div class="btn-block">
                    <button @click='closeTip'>OK, lest’s go!</button>
                </div>
                <img src="@/assets/img/icon/close.svg" alt="" class="close pointer" @click='closeTip'>
            </div>  
        
        </transition>
    </div>
</template>
<script>
export default {
    name:"FirstTimeTips",
    methods:{
        closeTip(){
            this.$emit("closeTip");
        }
    }
}
</script>
<style lang="scss">
@import '@/assets/scss/userVariables';
.firsttimetips{
        position: absolute;
        width: 500px;
        height: 340px;
        top:50%;
        left: 50%;
        transform:translate(-50%,-50%);
        background-color: rgba(255, 255, 255, 0.95);
        border-radius: 11.5px;
        padding: 14px 24px;
        @media screen and (max-width:640px){
            max-width: 100vw;
        }
        .title{
            font-size: $smallest_font;
            font-weight: bold;
            text-align: left;
        }
        .close{
            position: absolute;
            top: 14px;
            right: 10px;
        }
        .tipsImg{
            width: 90%;
            margin:12% auto 0;
        }
        .btn-block{
            margin-top: 13%;
            text-align: right;
            button{
                width:114px;
                color: #fff;
                background-color: $primary_color_1;
                border: none;
                border-radius: 7px;
                height: 35px;
                font-size: $body1;
            }
        }
    }
</style>