<template>
    <div class="keyboard">
        <div class="flex">
            <div class="up-down-container">
                <div class="up pointer" @mousedown='emitKeepMoveCamera(`[`)' @mouseup='emitResetKeyboard' @mouseleave='emitResetKeyboard' @pointerdown='emitKeepMoveCamera(`[`)' @pointerup='emitResetKeyboard' @pointerout='emitResetKeyboard'>
                    <img v-if='keyInputs.includes(`[`) || keyTouchs===`[`' src="@/assets/img/keyboard/camera-up-active.svg" alt="">
                    <img v-else src="@/assets/img/keyboard/camera-up.svg" alt="">
                    <label :class='{active: keyInputs.includes(`[`) || keyTouchs===`[`}'>[</label>
                </div>
                <div class="down pointer" @mousedown='emitKeepMoveCamera(`]`)' @mouseup='emitResetKeyboard' @mouseleave='emitResetKeyboard' @pointerdown='emitKeepMoveCamera(`]`)' @pointerup='emitResetKeyboard' @pointerout='emitResetKeyboard'>
                    <img v-if='keyInputs.includes(`]`) || keyTouchs===`]`' src="@/assets/img/keyboard/camera-down-active.svg" alt="">
                    <img v-else src="@/assets/img/keyboard/camera-down.svg" alt="">
                    <label :class='{active: keyInputs.includes(`]`) || keyTouchs===`]`}'>]</label>
                </div>
            </div>
            <div class="button-container">
                <div class="control-button pointer" v-for="(button) in tutorialList" :key="button.key" @mousedown='emitKeepMoveCamera(button.key)' @mouseup='emitResetKeyboard' @mouseleave='emitResetKeyboard' @pointerdown='emitKeepMoveCamera(button.key)' @pointerup='emitResetKeyboard' @pointerout='emitResetKeyboard'>
                    <label for="" class='notranslate' :class="{
                        active: keyInputs.includes(button.key) || keyInputs.includes(button.key1) || keyTouchs===button.key
                    }">{{button.key.toUpperCase()}}</label>
                    <div class="icon" :style="{
                            backgroundImage: `url(${button.img})`
                        }"
                        :class="{
                            active: keyInputs.includes(button.key) || keyTouchs===button.key,
                            activeQ: (keyInputs.includes(`q`) || keyTouchs==='q' || keyInputs.includes(`ArrowLeft`)) && button.key==='q',
                            activeE: (keyInputs.includes(`e`) || keyTouchs==='e' || keyInputs.includes(`ArrowRight`)) && button.key==='e',
                            activeArrow: (keyInputs.includes(`ArrowUp`) && button.key==='w') || keyInputs.includes(`ArrowDown`) && button.key==='s',
                        }">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import arrow from '@/assets/img/keyboard/arrow.svg'
import rotate from '@/assets/img/keyboard/rotate.svg'
export default {
    name:'Keyboard',
    data: () => (
        {
            tutorialList:
            [
                {
                    key: 'q',
                    key1:'ArrowLeft',
                    img: rotate,
                    style:{
                        rotate: '0deg',
                        scale: '1'
                    }
                },
                {
                    key: 'w',
                    key1:'ArrowUp',
                    img: arrow,
                    style:{
                        rotate: '0deg',
                        scale: '1'
                    }
                },
                {
                    key:'e',
                    key1:'ArrowRight',
                    img:rotate,
                    style:{
                        rotate: '0deg',
                        scale: '-1,1'
                    }
                },
                {
                    key: 'a',
                    key1:'A',
                    img: arrow,
                    style:{
                        rotate: '-90deg',
                        scale: '1'
                    }
                },
                {
                    key: 's',
                    key1:'ArrowDown',
                    img: arrow,
                    style:{
                        rotate: '-180deg',
                        scale: '1'
                    }
                },
                {
                    key: 'd',
                    key1:'D',
                    img: arrow,
                    style:{
                        rotate: '90deg',
                        scale: '1'
                    }
                }
            ],
            keyTouchs: '',
        }
    ),
    props:{
        keyInputs:{
            default: [],
            type: Array,
        }
    },
    mounted(){
    },
    methods:{
        emitKeepMoveCamera(key) {
            this.$emit('emitKeepMoveCamera', key);
            this.keyTouchs = key;
        },
        emitResetKeyboard() {
            this.$emit('emitResetKeyboard');
            this.keyTouchs = '';
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_reset.scss';
@import '@/assets/scss/_mixins.scss';
.flex{
    display: flex;
}
.up-down-container{
    display: flex;
    flex-direction: column;
    margin-right: 28px;
    justify-content: space-between;
    .up, .down{
        width: 50px;
        height: 52.8px;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(2.98634px);
        display: flex;
        justify-content: center;
        align-items: center;
        > img {
            width: 24px;
            height: 24px;
        }
        > label {
            position: absolute;
            right: 6px;
            top: 6px;
            color: #fff;
            font-size: 12px;
            &.active {
                color: #4454FF;
            }
            @include pad-768 {
                display: none;
            }
        }
    }
    .up{
        border-radius: 6px 6px 0 0;
    }
    .down{
        border-radius: 0 0 6px 6px;
    }
}
.keyboard{
    position:absolute;
    bottom: 18px;
    right: 18px;
    &.active{
        z-index: 2;
    }
    @include pad-768{
        right: 50%;
        transform: translate(50%, 0);
        bottom: 100px;
        // opacity: 0;
        // pointer-events: none;
    }
    @include mobile-576 {
        transform: translate(50%, 0) scale(1);
        transform-origin: bottom;
        bottom: 100px;
    }
    .button-container{
        display: flex;
        flex-wrap: wrap;
        width: 165px;
        margin: 0 auto;
        justify-content: space-between;
        color:#fff;
        .control-button{
            width: 31%;
            padding-bottom: 29.73%;
            border-radius: 6px;
            position:relative;
            margin-bottom: 8px;
            background: rgba(0, 0, 0, 0.5) !important;
            backdrop-filter: blur(2.98634px) !important;
            &:nth-of-type(n+4){
                margin-bottom:0;
            }
            label{
                position: absolute;
                right: 6px;
                top: 6px;
                // transition: .1s ease-in all;
                font-size: 12px;
                color:#fff;
                &.active{
                    color: #4454FF;
                }
                @include pad-768 {
                    display: none;
                }
            }
            &:nth-child(3){
                .icon{
                    transform: translate(-50%, -50%) scaleX(-1);
                    &.activeE,&.activeArrowE{
                        background-image: url('../assets/img/keyboard/rotate-active.svg') !important;
                        // transform: translate(-50%, -50%) rotate(1880deg);
                    }
                }
            }
            &:nth-child(4){
                .icon{
                    transform: translate(-50%, -50%) rotate(-90deg);
                }
            }
            &:nth-child(5){
                .icon{
                    transform: translate(-50%, -50%) rotate(-180deg);
                }
            }
            &:nth-child(6){
                .icon{
                    transform: translate(-50%, -50%) rotate(90deg);
                }
            }
            .icon{
                position: absolute;
                left: 50%;
                top: 57%;
                transform-origin: center;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                // transition: .1s ease-in all;
                transform: translate(-50%, -50%);
                width: 16px;
                height: 16px;
                @include pad-768 {
                    top: 50%;
                }
                &.active,&.activeArrow{
                    background-image: url('../assets/img/keyboard/arrow-active.svg') !important;
                }
                &.activeQ,&.activeArrowQ{
                    background-image: url('../assets/img/keyboard/rotate-active.svg') !important;
                    transform: translate(-50%, -50%);
                }
                // &:nth-of-type(1), &:nth-of-type(3){
                //     width: 18px;
                //     height: 18px;
                // }
            }
            
        }
    }
}
</style>