<template>
  <div id="app">
    <LoadingSaving v-show="loading"/>
    <router-view/>
  </div>
</template>
<style lang="scss">
@import './assets/css/normalize.css';
@import './assets/scss/global.scss';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #F9F8FD;
  min-height: 100vh;
  overflow: hidden;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
ul,ol {
  margin:0;
  padding:0;
  list-style:none
}
</style>
<script>
import {mapActions,mapState } from "vuex";
// import {getVenderProfile,getUserData} from '@/api'
import LoadingSaving from "@/components/GlobalLoad";
export default {
  data(){
    return{
      // stats: null,
    };
  },
  computed: {
    ...mapState("loader", ["loading"])
  },
  components:{LoadingSaving},
  methods:{
    ...mapActions(['setVendor','setLogin']),
  },
  destroyed(){
  }
}
</script>