<template>
    <div class="screenshot">
        <div class="bg"></div>
        <div class="main">
            <div class="title">Take Photo</div>
            <img class="shot" :src="screenShotSrc" alt="">
            <img src="@/assets/img/icon/close.svg" alt="" class="close pointer" @click='close'>
            <div class="btn-block">
                <button @click='close'>Take another photo</button>
                <button @click='downloadImg'>Save image</button>
                <img class="successSave" src="@/assets/img/icon/successSave.svg" alt="">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"screenShot",
    data:()=>({

    }),
    props:{
        screenShotSrc:{
            default:""
        }
    },
    methods:{
        downloadImg(){
            let link = document.createElement('a')
            link.href = this.screenShotSrc
            link.download = 'sharephoto.png'
            link.click()
        },
        close(){
            this.$emit("close")
        }
    }
}
</script>
<style lang="scss">
@import '@/assets/scss/userVariables.scss';
@import '@/assets/scss/_variables.scss';
.screenshot{
    position: fixed;
    left:0;
    top:0;
    bottom:0;
    right:0;
    z-index: 99;
    .bg{
        background-color: rgba($color: #333333, $alpha: 0.6);
        width:100%;
        height:100%;
    }
    .main{
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        width: 50%;
        background-color: #fff;
        max-width: 750px;
        padding-top: 14px;
        padding-bottom: 15px;
        border-radius: 10px;
        min-width:320px;
        .title{
            font-size: $smallest_font;
            font-weight: bold;
            text-align: left;
            margin-bottom: 3%;
            padding-left: 3%;
        }
        .shot{
            width: 87%;
            margin: 0 auto 30px;
        }
        .close{
            position: absolute;
            top: 10px;
            right: 10px;
            width: 22px;
        }
        .btn-block{
            position: relative;
            font-size: $body1;
            display: flex;
            justify-content: flex-end;
            width: 87%;
            //padding: 0 3%;
            margin: 0 auto;
            button:nth-child(1){
                color:  $primary_color_1;
                border: none;
                background-color: transparent;
                margin-right: 4%;
            }
            button:nth-child(2){
                color: #fff;
                background-color: $primary_color_1;
                border: none;
                line-height: 2.5;
                padding: 0 10px;
                border-radius: 7px;
                box-shadow: 2px 5px 10px 1px rgba($color: #000000, $alpha: 0.15);
            }
            .successSave{
                position:absolute;
                left: 0;
                top:50%;
                transform:translateY(-50%);
                width: 21%;
                width:123px;
                @include for-size(tablet-portait-only) {
                    top: -42%;
                    left:auto;
                    right:0;
                }
            }
        }
    }
}
</style>