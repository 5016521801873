import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios'
Vue.use(Vuex);
import { loader } from './modules/loader';

//const socketHost = process.env.NODE_ENV==='development'? 'https://stage.partyisland.io/': `${window.location.origin}/`;
//const socketHost ="https://dev-rest-service.dlll.nccu.edu.tw/"
const socketHost ="https://dev-chatroom-websockets-service.dlll.nccu.edu.tw/"
const state = {
    loginStatus: false,
    userType: 'vendor',
    avatar: {
      id: null,
      url: ''
    },
    customer: {
      _id: null,
    },
    vendor: {},
    sellerModel:[],
    sellershareModel:[],
    exhData:[],
    editingExh:{},
    userId: null,
    socket: null,
    // socket: io(socketHost),
}
const getters = {
    getAvatar: (state) => {
        return state.avatar;
    },
    customer: (state) => {
        return state.customer;
    },
    vendor: (state) => {
        return state.vendor;
    },
    sellerModel:(state) =>{
      return state.sellerModel
    },
    sellershareModel:(state) =>{
      return state.sellershareModel
    },
    exhData:(state) =>{
      return state.exhData
    },
    editingExh:(state) =>{
      return state.editingExh
    },
    getSocket:(state)=>{
      return state.socket
    },
}
const mutations = {
    setAvatarData(state, data) {
        state.avatar = data
    },
    clearAvatarData(state){
      state.avatar.id = null
      state.avatar.url = ''
    },
    setLogin(state, data) {
        state.customer = data;
        state.loginStatus = true;
    },
    setLogout(state){
      state.customer = {};
    },
    setVendor(state, data) {
        state.vendor = data
    },
    setSellerModel(state,data){
      state.sellerModel = data
    },
    setSellershareModel(state,data){
      state.sellershareModel = data
    },
    setExhData(state,data){
      state.exhData = data
    },
    setEditingExh(state,data){
      state.editingExh = data
    },
    clearSeller(state){
      state.vendor = {};
    }
}
const actions = {
    setAvatarData({ commit }, data) {
        commit("setAvatarData", data)
    },
    clearAvatarData({ commit }){
      commit("clearAvatarData")
    },
    setLogin({ commit }, data) {
        if(!data){
          console.warn('prepare login data as argument before calling setLogin.');
          return;
        }
        if (data.token) {
            localStorage.setItem("token", data.token)
            localStorage.setItem("userId", data.user._id)
            localStorage.setItem("type", data.user.type)
            const thisToken = 'Bearer '+window.localStorage.getItem("token");
            axios.defaults.headers.common['Authorization'] = thisToken;
            commit("setLogin", data.user)
        } else {
            localStorage.setItem("userId", data._id)
            localStorage.setItem("type", data.type)
            commit("setLogin", data)
        }
        if(data.user)
          return data.user.type;
    },
    setLogout({commit}){
      localStorage.removeItem("token");
      localStorage.removeItem("type");
      localStorage.removeItem("currentTheme")
      commit("setLogout");
    },
    setVendor({ commit }, data) {
        commit("setVendor", data)
    },
    setSellerModel({commit},data){
      commit("setSellerModel",data)
    },
    setSellershareModel({commit},data){
      commit("setSellershareModel",data)
    },
    setExhData({commit},data){
      commit("setExhData",data)
    },
    setEditingExh({commit},data){
      commit("setEditingExh",data)
    },
    clearSeller({commit}){
      commit("clearSeller")
    }
}

export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
    modules: {
      loader
    }
});
