<template>
    <canvas id='test-canvas'></canvas>
</template>
<style scoped lang='scss'>
*{
    overflow: hidden;
}
#test-canvas{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}
</style>
<script>
import * as Babylon from '@babylonjs/core';
import "@babylonjs/loaders";//Load External Assets.
import "@babylonjs/inspector";//Display fps, others.

export default {
    data(){
        return{
            engine: null,
            scene: null,
            camera: null,
            testData: null,
        }
    },
    methods:{
        init(){
            let canvas = document.getElementById('test-canvas');
            this.engine = new Babylon.Engine(canvas, true);
            this.scene = new Babylon.Scene(this.engine);
            this.scene.createDefaultCameraOrLight(true, true, true);
            this.scene.debugLayer.show();
            window.scene = this.scene;

            Babylon.SceneLoader.Append('static/models/', 'ex06.gltf', this.scene, (container)=>{
                let meshes = container.meshes;
                meshes[2].position.y -= 10;//plane
            });
            this.engine.runRenderLoop(()=>{
                this.scene.render();
                this.engine.resize();
            })
        }
    },
    mounted(){
        this.init();
    },
    destroyed(){
        this.engine.stopRenderLoop();
    },
}
</script>