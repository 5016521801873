<template>
    <transition name="el-fade-in-linear">
        <div class="dialog-component" v-show="dialogToggle" @click.self="active">
            <div class="dialog-slot-frame">
                <slot class='dialog-slot' name='dialog-content'></slot>
                <img v-if='needClose' class='close' @click='active' src="@/assets/img/icon/close.svg" alt="">
                <div class="buttons" v-if='needButton'>
                    <button @click='dialogToggle=false'>{{buttonLeft}}</button>
                    <button @click='emitConfirm'>{{buttonRight}}</button>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    data(){
        return{
            dialogToggle: false,
        }
    },
    props:{
        needClose:{
            type: Boolean, default: false,
        },
        needButton:{
            type: Boolean, default: true,
        },
        buttonLeft:{
            type: String, default: "Cancel"
        },
        buttonRight:{
            type: String, default: "Confirm"
        }
    },
    methods:{
        active(){
            this.dialogToggle = !this.dialogToggle;
            this.$emit('signupInit');
        },
        emitConfirm(){
            this.$emit('emitConfirm');
        }
    }
}
</script>
<style lang="scss" scoped>
.dialog-component {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(51, 51, 51, 0.7);
    z-index: 999;
    .dialog-slot-frame{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 5px;
        // padding: 50px 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        @media screen and (max-width:600px) {
            width: 95%;
            max-width: 420px;
            min-width: 290px;
            // padding: 35px 20px;
        }
        .buttons{
            position: absolute;
            right: 14px;
            bottom: 14px;
            // border: 1px solid red;
            button{
                border: none;
                width: 80px;
                height: 35px;
                font-size: 14px;
                color: #4454ff;
                background-color: #fff;
                border-radius:6px;
                &:nth-child(2){
                    margin-left: 10px;
                    background-color: #4454ff;
                    color: #fff;
                }
            }    
        }
    }
    .close {
        position: absolute;
        top: 14px;
        right: 14px;
        width: 20px;
        cursor: pointer;
        @media screen and (max-width:400px) {
            top: 10px;
            right: 10px;
        }
    }
}
</style>