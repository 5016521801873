var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"keyboard"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"up-down-container"},[_c('div',{staticClass:"up pointer",on:{"mousedown":function($event){return _vm.emitKeepMoveCamera("[")},"mouseup":_vm.emitResetKeyboard,"mouseleave":_vm.emitResetKeyboard,"pointerdown":function($event){return _vm.emitKeepMoveCamera("[")},"pointerup":_vm.emitResetKeyboard,"pointerout":_vm.emitResetKeyboard}},[(_vm.keyInputs.includes("[") || _vm.keyTouchs==="[")?_c('img',{attrs:{"src":require("@/assets/img/keyboard/camera-up-active.svg"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/img/keyboard/camera-up.svg"),"alt":""}}),_c('label',{class:{active: _vm.keyInputs.includes("[") || _vm.keyTouchs==="["}},[_vm._v("[")])]),_c('div',{staticClass:"down pointer",on:{"mousedown":function($event){return _vm.emitKeepMoveCamera("]")},"mouseup":_vm.emitResetKeyboard,"mouseleave":_vm.emitResetKeyboard,"pointerdown":function($event){return _vm.emitKeepMoveCamera("]")},"pointerup":_vm.emitResetKeyboard,"pointerout":_vm.emitResetKeyboard}},[(_vm.keyInputs.includes("]") || _vm.keyTouchs==="]")?_c('img',{attrs:{"src":require("@/assets/img/keyboard/camera-down-active.svg"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/img/keyboard/camera-down.svg"),"alt":""}}),_c('label',{class:{active: _vm.keyInputs.includes("]") || _vm.keyTouchs==="]"}},[_vm._v("]")])])]),_c('div',{staticClass:"button-container"},_vm._l((_vm.tutorialList),function(button){return _c('div',{key:button.key,staticClass:"control-button pointer",on:{"mousedown":function($event){return _vm.emitKeepMoveCamera(button.key)},"mouseup":_vm.emitResetKeyboard,"mouseleave":_vm.emitResetKeyboard,"pointerdown":function($event){return _vm.emitKeepMoveCamera(button.key)},"pointerup":_vm.emitResetKeyboard,"pointerout":_vm.emitResetKeyboard}},[_c('label',{staticClass:"notranslate",class:{
                    active: _vm.keyInputs.includes(button.key) || _vm.keyInputs.includes(button.key1) || _vm.keyTouchs===button.key
                },attrs:{"for":""}},[_vm._v(_vm._s(button.key.toUpperCase()))]),_c('div',{staticClass:"icon",class:{
                        active: _vm.keyInputs.includes(button.key) || _vm.keyTouchs===button.key,
                        activeQ: (_vm.keyInputs.includes("q") || _vm.keyTouchs==='q' || _vm.keyInputs.includes("ArrowLeft")) && button.key==='q',
                        activeE: (_vm.keyInputs.includes("e") || _vm.keyTouchs==='e' || _vm.keyInputs.includes("ArrowRight")) && button.key==='e',
                        activeArrow: (_vm.keyInputs.includes("ArrowUp") && button.key==='w') || _vm.keyInputs.includes("ArrowDown") && button.key==='s',
                    },style:({
                        backgroundImage: ("url(" + (button.img) + ")")
                    })})])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }