import Vue from 'vue';
import VueRouter from 'vue-router';

import PerformaceTest from '@/views/PerformanceTest.vue';

//page loading
import userMain from '@/views/User/Main.vue';
import userLogin from '@/views/User/Login.vue';
import Entrance from '@/views/User/Entrance.vue';
import Exhibition from '@/views/User/Exhibition.vue';
import LandingPage from '@/components/LandingPage.vue';


Vue.use(VueRouter)

const routes = [
    {
        path: '/performance-test',
        name: 'PerformanceTest',
        component: PerformaceTest
    },
    {
        path: '/view-mdl',
        name: 'ViewModel',
        component: () => import('@/views/ViewSpecificModel.vue')
    },
    {
        path: '/',
        component: userMain,
        children: [
            {
                path: '',
                name: 'Entrance',
                meta: { requiresAuth: true },
                component: Entrance
            },
            {
                path: 'exhibition',
                name: 'Exhibitions',
                meta: { requiresAuth: true },
                component: Exhibition
            },
            {
                path: 'user/login',
                name: 'UserLogin',
                component: userLogin
            },
            {
                path: 'landing',
                name: 'LandingPage',
                component: LandingPage
            },
        ]
    },
    {
        path:'/reset_password',
        component: () => import('@/views/ResetPassword.vue'),
    },
    {
        path: '/vendor',
        component: () => import('@/views/Vendor/Vendor.vue'),
        children: [
            {
                path: '',
                name: 'VendorHome',
                component: () => import('@/views/Vendor/VendorHome.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: 'signup',
                name: 'VendorSignup',
                component: () => import('@/views/Vendor/VendorSignup.vue'),
            },
            {
                path: 'edit',
                name: 'VendorEdit',
                component: () => import('@/views/Vendor/VendorEdit.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: 'customized',
                name: 'Customized',
                component: () => import('@/views/Vendor/ModelCustomized.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: 'exhibitionedit',
                name: 'ExhibitionEdit',
                component: () => import('@/views/Vendor/ExhibitionEdit.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: 'upgrade',
                name: 'Upgrade',
                component: () => import('@/views/Vendor/Upgrade.vue'),
                //meta: { requiresAuth: true },
            },
            {
                path: 'login',
                name: 'VendorLogin',
                component: () => import('@/views/Vendor/Login.vue')
            }
        ]
    },
    {
        path: '/admin',
        component: () => import('@/views/Admin/Admin.vue'),
        children: [
            {
                path: '',
                name: 'Admin',
                meta: { requiresAuth: true },
                component: () => import('@/views/Admin/Manage.vue')
            },
            {
                path: 'login',
                name: 'AdminLogin',
                component: () => import('@/views/Admin/Login.vue')
            },
            {
                path: 'edit',
                name: 'AdminEdit',
                meta: { requiresAuth: true },
                component: () => import('@/views/Admin/Edit.vue')
            },
            {
                path: 'letter',
                name: 'AdminLetter',
                meta: { requiresAuth: true },
                component: () => import('@/views/Admin/Letter.vue')
            },
            {
                path: 'add',
                name: 'AdminAdd',
                meta: { requiresAuth: true },
                component: () => import('@/views/Admin/AddNew.vue')
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    //base: 'web_exhibitions',
    base: '',
    routes
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export default router
