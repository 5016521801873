import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { HostUrl, refresh } from '@/api';

Vue.use(ElementUI);

// window.modelUrl = location.origin+'/web_exhibitions/'
window.modelUrl = location.origin + '/'
window.databaseUrl = HostUrl;
Vue.directive('visible', function (el, binding) {
    el.style.visibility = !!binding.value ? 'visible' : 'hidden';
});
Vue.directive('click-outside',{
    bind: function (el, binding, vnode) {
        el.eventSetDrag = function () {
            el.setAttribute('data-dragging', 'yes');
        }
        el.eventClearDrag = function () {
            el.removeAttribute('data-dragging');
        }
        el.eventOnClick = function (event) {
            var dragging = el.getAttribute('data-dragging');
            // Check that the click was outside the el and its children, and wasn't a drag
            if (!(el == event.target || el.contains(event.target)) && !dragging) {
                // call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.addEventListener('touchstart', el.eventClearDrag);
        document.addEventListener('touchmove', el.eventSetDrag);
        document.addEventListener('click', el.eventOnClick);
        document.addEventListener('touchend', el.eventOnClick);
    }, unbind: function (el) {
        document.removeEventListener('touchstart', el.eventClearDrag);
        document.removeEventListener('touchmove', el.eventSetDrag);
        document.removeEventListener('click', el.eventOnClick);
        document.removeEventListener('touchend', el.eventOnClick);
        el.removeAttribute('data-dragging');
    },
  });
router.beforeEach((to, from, next) => {
    refresh();
    // 如果 router 轉跳的頁面需要驗證 requiresAuth: true
    if(to.matched.some(record => record.meta.requiresAuth)){
        const name = to.name;
        switch (name) {
            case 'Admin':
            case 'AdminLogin':
            case 'AdminEdit':
            case 'AdminLetter':
            case 'AdminAdd':
                if(localStorage.getItem("token")){
                    if(localStorage.getItem('type') == 'seller'){
                        router.push({ name: 'VendorHome' });
                    }
                    else if(localStorage.getItem('type') == 'normal'){
                        router.push({ name: 'Entrance' });
                    }
                    else{
                        next();
                    }
                }
                else{
                    router.push({ name: 'AdminLogin' });
                }
                break;
            case 'VendorHome':
            case 'VendorEdit':
            case 'Customized':
            case 'ExhibitionEdit':
            case 'Upgrade':
                if(localStorage.getItem("token")){
                    if(localStorage.getItem('type') == 'admin'){
                        router.push({ name: 'Admin' });
                    }
                    else if(localStorage.getItem('type') == 'normal'||localStorage.getItem('type') == 'visitor'){
                        router.push({ name: 'Entrance' });
                    }
                    else{
                        next();
                    }
                }
                else{
                    router.push({ name: 'LandingPage' });
                }
                break;
            case 'Entrance':
                if(localStorage.getItem("token")){
                    // if(localStorage.getItem('type') == 'admin'){
                    //     router.push({ name: 'Admin' });
                    // }
                    // else if(localStorage.getItem('type') == 'seller'){
                    //     router.push({ name: 'VendorHome' });
                    // }
                    // else{
                        next();
                    //}
                }
                // else if(localStorage.getItem("token")==='visitor'){
                //     next();
                // }
                else{
                    router.push({ name: 'LandingPage' });
                }
                break;
            case 'Exhibitions':
		
                if(to.query.watchType==='preview'){
                    if (localStorage.getItem("token") && localStorage.getItem('type') == 'seller'){
                        next();
                    }
                    else{
                        router.push({ name: 'LandingPage' });
                    }
                }
                else{
                    if(localStorage.getItem("token")){
                        next();
                    }
                    else{
                        //修正無登入也可進展間
                        //router.push({ name: 'LandingPage' });
			next();
                    }
                }
                break;
        }
    }else{
        next();
    }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

refresh();
