<template>
    <div class="expoblock-header">
        <img src="@/assets/img/icon/expo-logo.svg" alt="">
        <div class="account-box" v-if="!customer._id">
            <img src="@/assets/img/icon/login.svg" alt="">
            <div class="login" @click='activeLogin'>Login</div>
            <span>/</span>
            <div class="signup" @click='activeSignup'>Signup</div>
        </div>
        <div class="login-box" v-if="customer._id">
            <div class="visit btn" @click="goIsland">
                <img src="@/assets/img/icon/visit.svg" alt="">
                <span>Visit Island</span>
            </div>
            <div class="updrade btn" @click="goPanel" v-if="customer._id && customer.type!='admin'">
                <img src="@/assets/img/icon/brick.svg" alt="">
                <span >{{customer.type=='normal'?`Upgrade`:`My blocks`}}</span>
            </div>
            <div class="user" >
                <div class="avatar" :style="{backgroundImage: 'url(' + userImg + ')'}"></div>
                <!-- <p>{{vendor.companyName}}</p> -->
                <div class="union" @click="unionToggle=!unionToggle" v-click-outside="unionToggleMethod">
                    <img src="@/assets/img/nav/chevronDown.svg" alt="">
                    <div class="message" v-if="unionToggle">
                        <div class="poly"></div>
                        <p id="logout-btn" @click="headerLogout"  @mouseenter="hoverIcon=1" @mouseleave="hoverIcon=-1" :style="hoverIcon==1?{color:`#D2D2D2`}:{}">Logout(H2)</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import headIcon from "@/assets/img/icon/userIcon.svg";
import gearIcon from "@/assets/img/icon/gear.svg";
import {logout} from '@/api';
import { mapGetters,mapActions } from 'vuex';
export default {
    data() {
        return{ 
            unionToggle:false,
            hoverIcon: -1,
        }
    },
     Mounted(){
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'https://cdn.jsdelivr.net/npm/web3@1.8.2/dist/web3.min.js')
        document.head.appendChild(recaptchaScript)
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////        
        window.addEventListener("message", this.receiveMessage, false);
        this.headerLogout();
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////        
    },
    methods:{
        ...mapActions(["clearSeller","setLogout"]),
        activeLogin(){
            this.$emit('emitLogin');
        },
        activeSignup(){
            this.$emit('emitSignup');
        },
        unionToggleMethod(){
            this.unionToggle = false;
        },
        headerLogout(){
            console.log('H2 headerLogout');
            logout();
            this.clearSeller();
            this.setLogout();
            this.$emit("logout");
            document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.dlll.nccu.edu.tw; path=/; SameSite=None; Secure`;
        },
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////        
        receiveMessage(event) {
            // if (event.origin !== "https://blockchain-asset-platform.dlll.nccu.edu.tw") {
            //     return;
            // }
            console.log("H2 received message from " + event.origin + ": " + event.data);
            if (event.data === 'logout') {
                // setCookie(token, "", -1);
                this.headerLogout();
            }
        },
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////        
        goIsland(){
            this.$router.push('/');
        },
        goPanel(){
            if(this.customer.type=='normal')
                this.$emit('openUpgrade');
            else
                window.location.href = location.protocol+'//'+window.location.host+'/vendor';

        },
    },
    computed:{
       ...mapGetters(['customer','vendor']),
       userImg(){
            if(this.vendor.avatarUrl)
                return this.vendor.avatarUrl
            else if(this.type=='admin_letter'||this.type=='admin_manage')
                return gearIcon
            else 
                return headIcon
        } 
    }
}
</script>
<style lang="scss" scoped>
.expoblock-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color:#333333;
    padding: 5px 24px;
    height:64px;
    >img{
        width: 49px;
    }
    .account-box{
        display: flex;
        align-items: center;
        color: #efefef;
        font-size: 14px;
        img{
            width:19px;
        }
        .login{
            margin-left: 5px;
            cursor: pointer;
        }
        .signup{
            cursor: pointer;
        }
        span{
            margin: 0 5px;
        }
    }
    .login-box{
        color:#fff;
        font-size:14px;
        display: flex;
        align-items: center;
        .visit,.updrade{
            display: inline-block;
            margin-right: 15px;
            img{
                display: inline-block;
                vertical-align: middle;
                width:14px;
                margin-right:10px;
            }
            span{    
                vertical-align: middle;
            }
        }
        .user{
            display: flex;
            align-items: center;
            color:black;
            font-size: 14px;
            font-weight: 400;
            // @include for-size(tablet-landscape-only) {
            //     right: 12%;
            // }
            p{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 60px;
                font-size: 12px;
                margin: 5px 0;
                line-height: 1.5;
            }
            .avatar{
                width: 34px;
                padding-bottom:34px;
                background-size: 100%;
                margin-right: 10px;
                border-radius: 100%;
                background-position: center;
                min-width: 34px;
            }
            .union{
                position: relative;
                cursor:pointer;
                .message{
                    position:absolute;
                    background-color: #FFFFFF;
                    border-radius:10px;
                    padding: 10px;
                    width: 77px;
                    right: -125%;
                    top: 220%;
                    .poly{
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 11.5px 13px 11.5px;
                        border-color: transparent transparent #FFFFFF transparent;
                        position:absolute;
                        right: 10px;
                        top: -7px;

                    }
                    p{
                        color:#333333;
                    }
                }
            }
        }
    }
}
</style>
