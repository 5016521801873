<template>
    <div class="upgrade">
        <div class="main">
            <img class="cross btn" @click="close" src="@/assets/img/icon/cross_black.svg" alt="">
            <h3>{{titleTxt}}</h3>
            <p v-visible="upgradeStep==0">Become a blocker and build your own block!</p>
            <div class="container">
                <div class="step0" v-if="upgradeStep==0">
                    <div class="inputWrap">
                        <label for="name"><span class="mark">*</span>Name</label>
                        <input type="text" id="name" name="name" placeholder="Enter your name" v-model="form.name">
                    </div>   
                    <div class="inputWrap">
                        <label for="phone"><span class="mark">*</span>Phone</label>
                        <input v-model="form.phone" name="phone" id="phone" type="number" placeholder="Please fill in the phone number">
                    </div> 
                    <div class="inputWrap">
                        <label for="email"><span class="mark">*</span>Mail</label>
                        <input v-model="form.email" name="email" id="email" type="text" placeholder="Please fill in the mail">
                    </div> 
                    <div class="inputWrap">
                        <label for="introduction"><span class="mark">*</span>Introduction</label>
                        <input v-model="form.introduction" name="introduction" id="introduction" type="text" placeholder="Please fill in the introduction">
                    </div> 
                    <div class="inputWrap">
                        <label for="companyName"><span class="mark">*</span>Company</label>
                        <input v-model="form.companyName" name="companyName" id="companyName" type="text" placeholder="Please fill in the companyName">
                    </div>
                    <div class="inputWrap">
                        <label for="address"><span class="mark">*</span>Address</label>
                        <input v-model="form.address" name="address" id="address" type="text" placeholder="Please fill in the address">
                    </div>
                    <div class="checkWrap">
                        <el-checkbox v-model="policiesCheck"></el-checkbox><p>Accept to agree  our <span class="btn">privacy policies</span></p>
                    </div>
                </div>
                <div class="step1" v-if="upgradeStep==1">
                    <el-checkbox-group v-model="planCheckList">
                        <el-checkbox label="Exhibition"></el-checkbox>
                        <el-checkbox label="Campaign"></el-checkbox>
                        <el-checkbox label="Sales"></el-checkbox>
                        <el-checkbox label="Education"></el-checkbox>
                        <el-checkbox label="Other"></el-checkbox>
                    </el-checkbox-group>
                </div>
                <div class="step2" v-if="upgradeStep==2">
                    <el-checkbox-group v-model="jobCheckList">
                        <el-checkbox label="Student"></el-checkbox>
                        <el-checkbox label="Educator"></el-checkbox>
                        <el-checkbox label="Artist"></el-checkbox>
                        <el-checkbox label="Designer"></el-checkbox>
                        <el-checkbox label="Marketer"></el-checkbox>
                        <el-checkbox label="Other"></el-checkbox>
                    </el-checkbox-group>
                </div>
                <div class="stepCheck" v-if="upgradeStep==null">
                    <div class="dialog-cotent" slot='dialog-content'>
                        <img src="@/assets/img/icon/verify-email.svg" alt="">
                        <span class='gray mtop24 mbottom24'>We’ve sent you an email to {{this.form.email}}. The code will be expired within 10 mins.</span>
                        <div class="input-box">
                            <span style='color:#333'>Verification code</span>
                            <input style='margin: 15px 0' type="text" v-model="form.signupCode" name='signupCode' placeholder="Enter code" autocomplete="off">
                        </div>
                        <!-- <div class="forgot" style='text-align:center'>Already have an account?</div> -->
                        <span v-if="resndProgress==0" style="color:#FB4A93;text-align:center;margin: 9px 0 12px;font-size:12px">Your verification code has expired.</span>
                        <div class="button-box">
                            <button @click="verifiedMail">Get Started</button>
                        </div>
                        <span class="gray mtop24">Didn’t received email? <span style="color:#4454ff" class="btn" @click="resend">Resend email. ({{resndProgress}}s)</span></span>
                    </div>
                </div>
            </div>
            <div class="btnWrap" v-show="upgradeStep!=null">
                <button class="cancel" @click="preStep">{{cancelBtnText}}</button>
                <button class="submit" @click="nextStep">Next<span class="arrow"></span></button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { verifiedMail,sendVerified,resendVerified} from '@/api'
export default {
    data:()=>({
        form:{
            phone:'',
            name:'',
            email:'',
            introduction:'',
            companyName:'',
            address:'',
            emailToken:''
        },
        planCheckList:[],
        jobCheckList:[],
        policiesCheck:false,
        downloadTimer:null,
        resndProgress:30
    }),
    props:{
        upgradeStep:{
            type:Number,
            default:0,            
        }
    },
    mounted(){
        this.form.name = this.customer.nickname;
        this.form.email = this.customer.email;
    },
    methods:{
        nextStep(){
            let message = '';
            let vm = this;
            let res = function(){
                let result = true;
                let emptyArray = '';
                if(vm.upgradeStep==0){
                    Object.keys(vm.form).forEach(function(objectKey, index) {
                        let value = vm.form[objectKey];
                        if(value==''&&index!=6){
                            if(index!=0)emptyArray = emptyArray+',';
                            emptyArray = emptyArray+objectKey;
                            result = false;
                        }
                    });
                    if(!result)message = `${emptyArray} unfilled!`;
                    if(!vm.policiesCheck){
                        result = false;
                        message = `${message}Accept to agree!`
                    }
                }else if(vm.upgradeStep==1){
                    vm.planCheckList.length==0?result=false:result=true;
                    if(!result)message = 'Please choose at least one';
                }else if(vm.upgradeStep==2){
                    vm.jobCheckList.length==0?result=false:result=true;
                    if(!result)message = 'Please choose at least one';
                }
                return result;
            }();
            if(!res){
                vm.$message({
                    showClose: true,
                    message:message,
                    type: 'error',
                    duration: 1500,
                });
                return;
            }
            let param = this.form;
            this.$emit('updrageNextStep',param)
        },
        preStep(){
            this.$emit('updragePreStep')
        },
        close(){
            this.$emit('closeUpdrade')
        },
        async sendMail(){
            let vm = this;
            let param = {
                email:this.form.email
            }
            let res = await sendVerified(param).catch(function (error) {
                  if (error.response) {
                    console.log(error.response)
                    vm.$message({
                        showClose: true,
                        message: `${error.response.data.message}`,
                        type: 'error',
                        duration: 1500,
                    });
                  }
            });
            if(res.data.status==200){
                this.setCountdown();
                this.upgradeStep = null;
            }
        },
        async verifiedMail(){
            let vm = this;
            if(this.form.signupCode=='')return;
            let param = {
                email:this.form.email,
                verifiedCode:'bkhole'
            }
            let res = await verifiedMail(param).catch(function (error) {
                  if (error.response) {
                    console.log(error.response)
                    vm.$message({
                        showClose: true,
                        message: `${error.response.data.message}`,
                        type: 'error',
                        duration: 1500,
                    });
                  }
            });
            if(res.data.status==200){
                clearInterval(vm.downloadTimer);
                this.form.emailToken = res.data.data.token
                this.upgradeStep = 1;
            }
        },
        setCountdown(){
            let vm = this;
            let timeleft = 30;
            vm.downloadTimer = setInterval(function(){
                if(timeleft <= 0){
                    clearInterval(vm.downloadTimer);
                }
                vm.resndProgress = timeleft;
                timeleft -= 1;
            }, 1000);
        },
        async resend(){
            let param = {
                email:this.form.email
            }
            let res = await resendVerified(param).catch(function (error) {
                  if (error.response) {
                    console.log(error.response)
                    vm.$message({
                        showClose: true,
                        message: `${error.response.data.message}`,
                        type: 'error',
                        duration: 1500,
                    });
                  }
            });
            if(res.data.status==200){
                this.setCountdown();
            }
        },
    },
    computed:{
        ...mapGetters(['customer']),
        titleTxt(){
            switch (this.upgradeStep) {
                case 0:
                    return 'Join us as a blocker'
                    break;
                case 1:
                    return 'What do you plan to do'
                    break;
                case 2:
                    return 'Tell us about you'
                    break;
                default:
                    return 'Verify your email address'
                    break;
            }
        },
        cancelBtnText(){
            return this.upgradeStep==0?'Cancel':'Back';
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
.mtop24{
    margin-top: 24px;
}
.mbottom24{
    margin-bottom: 24px;
}
.fs12{
    font-size: 12px;
}
.fs14{
    font-size: 14px;
}
.upgrade{
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: rgba($color: #000000, $alpha: .6);
    z-index: 5;
    .main{
        width:35%;
        height:80vh;
        background-color:#ffffff;
        margin: auto;
        top:50%;
        transform:translateY(-50%);    
        position: relative;
        h3{
            font-size: 20px;
            margin: 0 0 3%;
        }  
        p{
            font-size: 12px;
            margin: 0 0 8%;
        }
        .cross{
            display: block;
            margin: 0 0 0 auto;
            padding: 2%;
        }
        .inputWrap{
            width: 336px;
            margin:0 auto 10px;
            label{
            font-weight: 500;
            display: block;
            text-align: left;   
            font-size: 18px;
            margin-bottom: 10px;
            position: relative;
            text-indent: -7px;
            span{
                position: absolute;
                right:0;
                bottom:0;
                font-size: 12px;
                color: #FF4CAD;
                font-weight: 400;
            }
            @include for-size(desktop-only) {
                font-size:14px;
            }
            .mark{
                position:static;
                font-size: 16px;
            }
        }
            input{
                font-size: 16px;
                line-height: 2.3;
                padding-left: 15px;
                width: 100%;
                border-color: #333333;
                &:focus{
                    border-color:$purple;
                }
                &.error{
                    border:1px solid #FF4CAD;
                }
                @include for-size(desktop-only) {
                    font-size:14px;
                }
            }
        }
        .checkWrap {
            text-align: left;
            width: 336px;
            margin: 20px auto 0;
        }
        .checkWrap p{
            display: inline-block;
            padding-left: 10px;
            line-height: 19px;
            font-size: 14px;
            color:#757575;
            span{
                text-decoration: underline;
            }
        }
        .container{
            .step0{
                overflow: auto;
                height: 50vh;
            }
            .step1,.step2{
                label{
                    display: block;
                }
            }
            ::v-deep .el-checkbox-group{
                padding: 0 13%;
                text-align: left;
            }
            ::v-deep .el-checkbox{
                margin-bottom: 10%;
            }
            ::v-deep .el-checkbox__inner{
                width: 17px;
                height: 17px;
            }
            ::v-deep .el-checkbox__inner::after{
                height: 9px;
                left: 6px;
                top: 1px;
            }
        }
        .btnWrap{
            bottom:10px;
            right:10px;
            position:absolute;
            button{
                color:#fff;
                @include blockShadow;
                border-radius: 4px;
                font-size: 14px;
                line-height: 2.4;
                width:94px;
                
                &.cancel{
                    color:$purple;
                    border:1px $purple solid;
                    margin-right:7px;
                }
                &.submit{
                    margin-left: 7px;
                }
                span{
                    background-image: url('~@/assets/img/icon/arrowL.svg');
                    display: inline-block;
                    background-size: 100%;
                    width: 11.8%;
                    padding-bottom: 19%;
                    transform: rotate(180deg);
                    margin-bottom: -2%;
                    margin-left: 10px;
                }
            }
        }
        .dialog-cotent {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 95%;
            width: 300px;
            margin: 30px auto 30px;
            .type-switch{
                display: flex;
                justify-content: space-between;
                margin-bottom: 36px;
                font-size: 14px;
                div{
                    width: 145px;
                    height: 23px;
                    cursor: pointer;
                    &.active{
                        position: relative;
                        transition: .3s;
                        &:after{
                            content: "";
                            width: 100%;
                            position: absolute;
                            top: 100%;
                            left: 0;
                            border-bottom: 1px solid #4454ff;
                        }
                    }
                }
            }
            .input-box{
                display: flex;
                flex-direction: column;
                input{
                    height: 36px;
                    padding:8px 14px;
                    letter-spacing: 1.2px;
                    border: 1px solid #333;
                    &::placeholder{
                        font-size: 13px;
                        color: #b1aeae;
                    }
                    &:nth-child(2){
                        margin-top: 14px
                    }
                }
                span{
                    margin: 6px 0px;
                    align-self: flex-start;
                    color: transparent;
                    user-select: none;
                    color: transparent;
                    // font-size: 14px;
                    &.active{
                        color: #fb4a93;
                    }
                }
            }
            .forgot{
                text-align: right;
                margin: 6px 0 30px 0;
                font-size: 14px;
                color: #4454ff;
                cursor: pointer;
            }
            .not-verified{
                color: #fb4a93;
                text-align: left;
                font-size: 14px;
                margin-bottom: 6px;
                height: 0;
                overflow: hidden;
                &.active{
                    height: auto;
                }
            }
            h6{
                font-size: 14px;
                margin: 18px 0 27px 0;
            }
            .button-box{
                display: flex;
                flex-direction: column;
                button{
                    height: 35px;
                    border: none;
                    border-radius: 5px;
                    background-color: #4454ff;
                    font-size: 14px;
                    color: #fff;
                    &:nth-child(2), &.last{
                        background-color: #f7f7f7;
                        color: #333;
                        margin: 10px 0 0 0;
                    }
                    &.only-text{
                        border: none;
                        background-color: transparent;
                    }
                    &:hover{
                        filter: drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.4));
                    }
                }
            }
            .or-block{
                display: flex;
                justify-content:space-between;
                align-items:center;
                font-size: 14px;
                margin: 36px 0 14px 0;
                div{
                    width: 100px;
                    height: 1.5px;
                    background-color: #efefef;
                }
            }
            .without-account{
                margin: 22px 0 14px 0;
                font-size: 14px;
                cursor: pointer;
                color: #4454ff;
            }
            .features{
                font-size: 12px;
                color: #b1aeae;
            }
            .gray{
                font-size: 14px;
                color: #b1aeae;
            }
        }
    }
}
</style>